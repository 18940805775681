<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 消费
 * @Date: 2020-12-24 19:31:02
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-12-28 19:25:08
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/consumption.vue
-->
<template>
  <div class="user_content">
    <div class="consum_box">
      <div>账户余额（元）</div>
      <div>{{ balanceMoney }}</div>
    </div>
    <div class="consum_bos">
      <div
        v-for="(item,index) in rule"
        :key="index"
        :class="con_index !== index? 'item_m':'item_m active'"
        @click="consumlist(item,index)"
      >
        <div>{{ item.showMoney }} 元</div>
        <div>仅需{{ item.actualMoney }}元</div>
      </div>
    </div>
    <div class="consum_btn">去充值（￥{{ con_actualMoney }}）</div>
    <p class="xy">点击“去充值”代表您已阅读并同意 <b @click="Reag">《充值协议》</b></p>
    <van-cell-group class="consum_list">
      <van-cell title="交易明细" is-link to="/Transactiondetails" />
      <van-cell title="修改密码" is-link @click="inpassword" />
      <van-cell title="佣金转入" is-link to="/commissionIn" />
    </van-cell-group>
    <van-popup v-model="pasw" closeable round @close="close">
      <div class="verCode">
        <div class="title">请输入验证码</div>
        <div class="tip">验证码发送至 {{ mobile }}</div>
        <van-field v-model="code" input-align="center" placeholder="请输入用户名" @input="monit" />
        <van-button class="Time" size="mini" :disabled="disabled" @click="inpassword">{{ getIcodeTxt }}</van-button>
        <van-button class="strom" :disabled="indisabled">下一步</van-button>
      </div>
    </van-popup>
    <van-popup v-model="Subins" closeable round>
      <div class="verCode">
        <div class="title">设置余额密码</div>
        <van-field v-model="word" placeholder="请输入6位数字密码" />
        <van-field v-model="passWord" placeholder="请确认输入密码" />
        <van-button class="strom" @click="changePassword">提交</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { Button, Cell, CellGroup, Field, Popup, Toast } from 'vant'

Vue.use(Cell).use(CellGroup).use(Toast).use(Popup).use(Field).use(Button)
import {
  setBalancePwd,
  authnewSend,
  getBalanceMoneyData
} from '@/services/userApi'
import Utils from '@/utils/aes.js'

const ALL_SECOND = 60
let timer = null
export default {
  data() {
    return {
      disabled: false,
      indisabled: true,
      getIcodeTxt: '获取',
      pasw: false,
      Subins: true,
      code: '',
      rule: [],
      balanceMoney: '',
      con_index: 0,
      con_actualMoney: '',
      mobile: '',
      word: '',
      passWord: ''
    }
  },
  created() {
    this.mobile = localStorage.getItem('mobile')
    this.getList()
  },
  methods: {
    getList() {
      getBalanceMoneyData().then(res => {
        if (Number(res.code) === 200) {
          this.rule = res.data.rule
          this.balanceMoney = res.data.balanceMoney
          this.con_index = 0
          this.con_actualMoney = this.rule[0].actualMoney
        } else {
          Toast(res.msg)
        }
      })
    },
    // 选择金额
    consumlist(t, v) {
      this.con_index = v
      this.con_actualMoney = t.actualMoney
    },
    Reag() {
      this.$router.push({ path: '/Reag' })
    },
    // 打开修改密码
    inpassword() {
      this.pasw = true
      let seconds = ALL_SECOND
      timer = setInterval(() => {
        seconds--
        if (seconds > 0) {
          this.getIcodeTxt = `(${seconds}s)`
          this.disabled = true
        } else {
          clearInterval(timer)
          this.getIcodeTxt = `重新获取`
          this.disabled = false
        }
      }, 1000)
      // 发起请求
      const prom = {
        mobile: this.mobile
      }
      authnewSend(prom).then(res => {
        if (Number(res.code) === 200) {
          // 空白无逻辑
        } else {
          clearInterval(timer)
          Toast(res.messages)
        }
      })
    },
    monit(t) {
      this.indisabled = t.length !== 6
    },
    close() {
      clearInterval(timer)
    },
    changePassword() {
      const prom = {
        encryptedData: Utils.encrypt(JSON.stringify({
          'word': this.word,
          'passWord': this.passWord
        }), 'zxcvbnmasdfghjkl', Math.floor(Math.random() * 99999999999999)),
        iv: Math.floor(Math.random() * 9999999999999999)// 偏移量（16位随机字符串）
      }
      setBalancePwd(prom).then(res => {
        if (Number(res.code) === 200) {
          this.Subins = false
          Toast('密码修改成功')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .consum_box {
    & > div:nth-child(1) {
      font-size: 14px;
      margin: 40px 13px 20px;
    }

    & > div:nth-child(2) {
      color: #E33F44;
      font-size: 39px;
      font-weight: 500;
      margin: 0 10px 20px;
    }
  }

  .consum_bos {
    margin: 13px;
    display: flex;

    .item_m {
      flex: 4;
      margin: 10px 5px 8px;
      padding: 20px 10px;
      border-radius: 5px;
      border: 1px solid #DDDDDD;
      color: #666666;
      font-size: 15px;
    }

    .item_m.active {
      color: #E33F44;
      border: 1px solid #E33F44;
    }
  }

  .consum_btn {
    background: #E33F44;
    color: #fff;
    padding: 15px;
    width: 96%;
    margin: auto;
    border-radius: 25px;
  }

  .xy {
    color: #666666;
    font-size: 13px;
    margin: 13px;

    & > b {
      color: #E33F44;
    }
  }

  .verCode {
    width: 300px;
    min-height: 300px;
    padding: 15px;
    border-radius: 7px;

    .title {
      color: #000;
      font-size: 15px;
      padding: 15px 0;
      font-weight: 500;
    }

    .tip {
      color: #666666;
      margin: 20px 0;
    }

    .inp {
      border-bottom: 1px solid #999;
    }

    .Time {
      margin: 15px 0;
    }

    .strom {
      width: 100%;
      padding: 13px;
      background: #E33F44;
      border-radius: 7px;
      color: #fff;
      margin-top: 10px;
    }
  }

  .van-cell__title {
    text-align: left;
  }
}
</style>
<style>
.inp input::-webkit-input-placeholder {
  text-align: center;
  font-size: 20px;
}
</style>
